// error code syntax:
// 5 digits with 2 first to indicate the category and last 3 the specific case

// user status error
exports.UNKNOWN_STATUS_ERROR = "0100"
// t("error:0100")
exports.NO_PLAN_ACTIVE = "0101"
// t("error:0101")
exports.NOT_PURCHASED = "0102"
// t("error:0102")
exports.LOGIN_REQUIRED = "0104"
// t("error:0104")
exports.PROFILE_NOT_PROVIDED = "0105"
// t("error:0105")

// permissions
exports.PERMISSION_DENIED = "0200"
// t("error:0200")

// payment error
exports.UNKNOWN_PAYMENT_ERROR = "0300"
// t("error:0300")
exports.SUBSCRIPTION_FAILED = "0301"
// t("error:0301")
exports.CHECKOUT_FAILED = "0302"
// t("error:0302")
exports.INSUFFICIENT_FUNDS = "0303"
// t("error:0303")
exports.THREED_SECURE_FAILED = "0304"
// t("error:0304")
exports.ALREADY_PURCHASED = "0305"
// t("error:0305")

exports.COUPON_NOT_STARTED = "0310"
// t("error:0310")
exports.COUPON_ENDED = "0311"
// t("error:0311")
exports.COUPON_CONSUMED = "0312"
// t("error:0312")
exports.COUPON_WRONG_OWNER = "0313"
// t("error:0313")
exports.COUPON_WRONG_ITEM = "0314"
// t("error:0314")
exports.COUPON_NOT_FOUND = "0315"
// t("error:0315")
exports.COUPON_ALDREADY_REDEEMED = "0316"
// t("error:0316")

// content error
exports.UNKNOWN_CONTENT_ERROR = "0400"
// t("error:0400")
exports.MAX_IMAGE_SIZE_EXCEED = "0401"
// t("error:0401")

// certification error
exports.EXAM_ALREADY_PASSED = "0501"
// t("error:0501")
exports.EXAM_COOLDOWN_LOCK = "0502"
// t("error:0502")
exports.EXAM_TIME_EXCEEDED = "0503"
// t("error:0503")
exports.EXAM_TIMEOUT = "0504"
// t("error:0504")
exports.EXAM_NOT_PASSED_YET = "0505"
// t("error:0505")

exports.CERTIFICATION_SHIPPED = "0510"
// t("error:0510")
exports.CERTIFICATION_SHIPPING_ALREADY_REQUIRED = "0511"
// t("error:0511")

exports.WEBINAR_ALREADY_REGISTERED = "0601"
// t("error:0601")
